import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import PropTypes from 'prop-types';

import calendar from '../../assets/icons/calender2.svg';
import '../../common/date-range-picker/style.css';
import Tooltip from '../../common/tooltip/Tooltip';
import {
    formatDate,
    getConvertDate,
    getDayFromDate,
} from '../../helper/dateHelper/dateUtils.js';
import HolidayManagement from '../../service/holiday-management';
import { addHolidayValidation } from '../../validation/dataValidation';

const HolidayAddModal = ({ onChange }) => {
    const [dateRange, setDateRange] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef(null);
    const initialRef = React.useRef(null);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const toast = useToast();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid },
        reset,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addHolidayValidation),
    });

    const [tooltip, setTooltip] = useState({
        visible: false,
        contents: '',
        top: 0,
        left: 0,
    });

    const workingDays = [0, 1, 2, 3, 4];

    const isHoliday = date => {
        const day = getDayFromDate(date);
        return !workingDays.includes(day);
    };

    const handleMouseEnter = (event, date) => {
        if (isHoliday(date)) {
            const rect = event.target.getBoundingClientRect();
            setTooltip({
                visible: true,
                contents: [`Weekend can’t be`, ` selected as holiday`],
                top: rect.top - 52,
                left: rect.left + rect.width / 2,
            });
        }
    };

    const handleMouseLeave = () => {
        setTooltip({ visible: false, contents: '', top: 0, left: 0 });
    };

    const handleModalClose = () => {
        reset();
        setDateRange(null);
        onClose();
    };

    const onSubmit = async data => {
        if (dateRange) {
            const [startFrom, endAt] = dateRange.map(date => {
                return formatDate(date);
            });

            const payload = {
                ...data,
                startFrom,
                endAt,
            };

            setDataLoaderOpen(true);
            try {
                const data = await HolidayManagement.addHolidays(payload);
                toast({
                    title: 'Successfully added',
                    description: 'Holiday has been added successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setDataLoaderOpen(false);
                onChange(data);
                handleModalClose();
            } catch (error) {
                setDataLoaderOpen(false);
                toast({
                    title: 'Add failed',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    return (
        <>
            <Flex align="center" onClick={onOpen} cursor="pointer">
                <Image src={calendar} alt="calendar" />
                <Text color="#0077C0" fontWeight="400" pl="8px">
                    Add new holiday
                </Text>
            </Flex>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={handleModalClose}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add new holiday</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl pt={2} isInvalid={errors.name}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="name"
                                    color="#464646"
                                >
                                    Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '100%', lg: '100%' }}
                                    >
                                        <Input
                                            {...register('name')}
                                            placeholder="e.g. Eid-Ul-Adha"
                                            bgColor="white"
                                            color="rgba(17, 17, 19, 0.60)"
                                            width="100%"
                                            border="1px"
                                            borderColor="rgba(17, 17, 19, 0.2)"
                                            fontSize="14px"
                                            autoComplete="off"
                                            borderRadius="4px"
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                        />
                                        <Flex>
                                            <FormErrorMessage>
                                                {errors.name &&
                                                    errors.name.message}
                                            </FormErrorMessage>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </FormControl>
                            <FormControl pt={6} isInvalid={errors.holidayDate}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="holidayDate"
                                    color="#464646"
                                >
                                    Date
                                </FormLabel>
                                <Controller
                                    control={control}
                                    {...register('holidayDate')}
                                    rules={{
                                        required: 'Holiday date is required...',
                                    }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div
                                            className="date-picker-wrapper"
                                            style={{
                                                width: '100%',
                                                position: 'relative',
                                            }}
                                        >
                                            <DateRangePicker
                                                format="dd/MM/yyyy"
                                                calendarAriaLabel="Toggle calendar"
                                                clearAriaLabel="Clear value"
                                                onChange={dates => {
                                                    setDateRange(dates);
                                                    onChange(dates);
                                                }}
                                                value={value || []}
                                                tileDisabled={({ date }) =>
                                                    isHoliday(
                                                        getConvertDate(date)
                                                    )
                                                }
                                                tileClassName={({ date }) =>
                                                    isHoliday(
                                                        getConvertDate(date)
                                                    )
                                                        ? 'holiday'
                                                        : ''
                                                }
                                                tileContent={({ date, view }) =>
                                                    view === 'month' &&
                                                    isHoliday(
                                                        getConvertDate(date)
                                                    ) ? (
                                                        <div
                                                            className="holiday-hover-area"
                                                            onMouseEnter={e =>
                                                                handleMouseEnter(
                                                                    e,
                                                                    getConvertDate(
                                                                        date
                                                                    )
                                                                )
                                                            }
                                                            onMouseLeave={
                                                                handleMouseLeave
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        ></div>
                                                    ) : null
                                                }
                                                clearIcon={null}
                                            />
                                            <Tooltip
                                                visible={tooltip?.visible}
                                                contents={tooltip?.contents}
                                                top={tooltip?.top}
                                                left={tooltip?.left}
                                            />
                                        </div>
                                    )}
                                />
                                <Flex>
                                    <FormErrorMessage>
                                        {errors.holidayDate &&
                                            errors.holidayDate.message}
                                    </FormErrorMessage>
                                </Flex>
                            </FormControl>
                            <Flex
                                direction="row"
                                justifyContent="flex-end"
                                mt="320px"
                                w={{ lg: '100%', base: '100%' }}
                            >
                                <Text
                                    cursor="pointer"
                                    fontSize="12px"
                                    pr="16px"
                                    color="#3F3F4E"
                                    alignSelf="center"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </Text>
                                <Button
                                    type="submit"
                                    w="119px"
                                    h="30px"
                                    fontSize="12px"
                                    bgColor="#0077C0"
                                    color="white"
                                    disabled={
                                        !isValid ||
                                        !dateRange ||
                                        dateRange.length === 0
                                    }
                                    _focus={{
                                        borderColor: 'none',
                                    }}
                                    _hover={{
                                        color: 'white',
                                        bgColor: '#0077C0',
                                    }}
                                    _disabled={{
                                        cursor: 'not-allowed',
                                        bgColor: '#DDDDDD !important',
                                        color: '#9A9A9A !important',
                                    }}
                                >
                                    Submit
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
HolidayAddModal.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default HolidayAddModal;
