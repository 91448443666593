import React, { useEffect, useState } from 'react';

import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';

const Search = ({ employee, getFilterData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [filterValue, setFiltervalue] = useState([]);

    const toggle = () => {
        setIsOpen(isOpen => !isOpen);
    };

    const handleFilterData = e => {
        const inputValue = e.target.value;
        setValue(inputValue);

        if (inputValue === '') {
            getFilterData();
            setIsOpen(false);
        } else {
            let findFilterData = employee.filter(item => {
                return item.name
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
            });

            if (findFilterData.length < 1) {
                findFilterData.push({
                    name: 'No Filter data',
                });
            }

            setFiltervalue(findFilterData);
            setIsOpen(true);
        }
    };

    useEffect(() => {
        setFiltervalue(employee);
    }, [employee]);

    return (
        <Box position="relative">
            <InputGroup width="292px" onClick={toggle}>
                <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                    focusBorderColor="none"
                    type="text"
                    placeholder="Search Employee..."
                    value={value}
                    onChange={e => handleFilterData(e)}
                />
            </InputGroup>
            {isOpen && (
                <Box
                    position="absolute"
                    zIndex="10"
                    top="45px"
                    width="292px"
                    minHeight="72px"
                    maxHeight="242px"
                    padding="12px"
                    borderRadius="4px"
                    border="1px"
                    borderColor="#D3D5DA"
                    background="#ffffff"
                    boxShadow="0px 4px 8px 0px rgba(100, 100, 102, 0.10)"
                    overflow="scroll"
                >
                    {filterValue.map(employee => {
                        return (
                            <Text
                                key={employee.id}
                                p="12px"
                                cursor="pointer"
                                _hover={{ bg: '#F9FAFB' }}
                                onClick={() => {
                                    getFilterData(employee.employeeId);
                                    setValue(employee.name);
                                    setIsOpen(false);
                                }}
                            >
                                {employee.name}
                            </Text>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

Search.propTypes = {
    employee: PropTypes.any,
    getFilterData: PropTypes.func,
};

export default Search;
