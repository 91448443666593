import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CustomLegend = ({ payload }) => {
    return (
        <Flex justifyContent="space-evenly" alignItems="center">
            {payload.map((entry, index) => (
                <Box
                    key={`legend-item-${index}`}
                    display="flex"
                    alignItems="center"
                >
                    <Box
                        width="16px"
                        height="16px"
                        backgroundColor={entry.payload.fill}
                        marginRight="8px"
                    />
                    <Text style={{ color: entry.payload.fill }}>
                        {entry.payload.name} {entry.payload.value}
                    </Text>
                </Box>
            ))}
        </Flex>
    );
};
CustomLegend.propTypes = {
    payload: PropTypes.array,
};
export default CustomLegend;
