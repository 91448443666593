import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Flex, Spacer, Text, useToast } from '@chakra-ui/react';

import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import PageTitle from '../../common/PageTitle';
import { LEAVE_LIST_BREADCRUMB } from '../../constants/breadcrumbData';
import EmployeeManagement from '../../service/employee-management';
import LeaveManager from '../../service/leave-manager';
import PartnerManagement from '../../service/partner-management';
import AllEmployeeLeaveData from '../components/AllEmployeeLeaveData';
import ApprovedLeaveChart from '../components/ApprovedLeaveChart';
import YearPicker from '../components/YearPicker';
import ForecastedLeave from '../components/forecasted-leave/ForecastedLeave';

const PAGE_TITLE = 'Employee Leave Details';

const LeaveDashboard = () => {
    let { page, size } = useParams();
    const currentYear = new Date().getFullYear();
    const toast = useToast();
    const [partners, setPartners] = useState([]);
    const [leads, setLeads] = useState([]);
    const [leaves, setLeaves] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [filteredData, setFilteredData] = useState([]);
    const [getId, setGetId] = useState();
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [employee, setEmployee] = useState();
    const displayYear =
        selectedYear === currentYear
            ? 'All information reflects the current year'
            : `Showing results for Year ${selectedYear}`;

    const totalApproveLeave = chartData[3]?.totalApproveLeave;
    const totalAutoApproveLeave = chartData[3]?.totalAutoApproveLeave;

    const pickedYear = year => {
        setSelectedYear(year);
        fetchLeaveData(getId, filteredData, year);
    };

    const findPartner = async () => {
        try {
            const allPartners = await PartnerManagement.getAllPartner();
            setPartners(allPartners.data);
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    const findLeads = async () => {
        try {
            const allLeads = await EmployeeManagement.getAllLeads();
            setLeads(allLeads?.data);
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const findEmployee = async () => {
        const size = '100';
        try {
            const allEmployee = await EmployeeManagement.getAllEmployee(
                0,
                size
            );
            setEmployee(allEmployee?.content);
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    async function getLeaveData() {
        try {
            const leaveData = await LeaveManager.getEmployeeLeaveDetails();
            setLeaves(leaveData);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const forecastedLeaveData = data => {
        setGetId(data);
    };
    const leadsAndPartnersPicker = data => {
        setFilteredData(data);
        setGetId(null);
    };

    async function fetchLeaveData(getId, filteredData, selectedYear) {
        setDataLoaderOpen(true);

        try {
            const response = await LeaveManager.getApprovedLeaves(
                getId,
                filteredData,
                selectedYear
            );
            const responseData = response;
            setChartData(responseData.data);
            setDataLoaderOpen(false);
            return true;
        } catch (error) {
            toast({
                title: 'Failed to fetch holidays',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
    }

    useEffect(() => {
        setSelectedYear(currentYear);
        findPartner();
        findLeads();
        findEmployee();
        getLeaveData();
        if (getId || filteredData.length > 0 || selectedYear) {
            fetchLeaveData(getId || '', filteredData, selectedYear);
        }
    }, [getId, filteredData, page, size]);

    return (
        <Box>
            <CustomBreadcrumb allBreadcrumbData={LEAVE_LIST_BREADCRUMB} />
            <Flex direction="row">
                <Box>
                    <PageTitle title={PAGE_TITLE} />
                    <Text color="#6C727F">{displayYear}.</Text>
                </Box>

                <Spacer />
                <Box>
                    <YearPicker
                        onChange={pickedYear}
                        selectedYear={selectedYear}
                    />
                </Box>
            </Flex>

            <Box py="24px">
                <Flex
                    direction="row"
                    flexWrap="wrap"
                    gap="3"
                    justifyContent="space-between"
                >
                    <ForecastedLeave
                        selectedYear={selectedYear}
                        chartData={chartData}
                        partners={partners}
                        leads={leads}
                        forecastedLeaveData={e => forecastedLeaveData(e)}
                        leadsAndPartnersPicker={e => leadsAndPartnersPicker(e)}
                    />
                    <ApprovedLeaveChart chartData={chartData} />
                </Flex>
            </Box>

            <Box>
                <AllEmployeeLeaveData
                    leaves={leaves}
                    partners={partners}
                    leads={leads}
                    selectedYear={selectedYear}
                    employee={employee}
                    totalApproveLeave={totalApproveLeave}
                    totalAutoApproveLeave={totalAutoApproveLeave}
                />
            </Box>
        </Box>
    );
};

export default LeaveDashboard;
