import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = date => {
    return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateRange = (startDate, endDate) => {
    return [
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).format('YYYY-MM-DD'),
    ];
};

export const getStartOfYear = year => {
    return dayjs(`${year}-01-01`).format('YYYY-MM-DD');
};

export const getEndOfYear = year => {
    return dayjs(`${year}-12-31`).format('YYYY-MM-DD');
};

const SANITIZE_DATE_REGEX = /\[.*?\]/g;

export const sanitizeDate = dateString => {
    return dateString.replace(SANITIZE_DATE_REGEX, '');
};

export const formatDateByZoneYear = (startFrom, endAt) => {
    const startDate = dayjs(startFrom);
    const endDate = dayjs(endAt);

    const formattedStart = startDate.format('DD MMM');

    const formattedEnd = endDate.format('DD MMM');

    const endYear =
        startDate.year() === endDate.year() ? `, ${endDate.year()}` : '';

    return formattedStart === formattedEnd
        ? formattedStart + endYear
        : `${formattedStart} - ${formattedEnd}${endYear}`;
};

export const formatDateByZone = (startFrom, endAt) => {
    const formattedStart = dayjs(startFrom).format('DD MMM, YYYY');
    const formattedEnd = dayjs(endAt).format('DD MMM, YYYY');

    return formattedStart === formattedEnd
        ? formattedStart
        : `${formattedStart} - ${formattedEnd}`;
};

export const applyDate = applyDate => {
    const formattedDate = dayjs(applyDate).format('DD MMM, YYYY');
    return formattedDate;
};

export const getCurrentDate = () => {
    return dayjs().tz('Asia/Dhaka').startOf('day');
};

export const getHolidayStart = startFrom => {
    return dayjs.tz(sanitizeDate(startFrom), 'Asia/Dhaka').startOf('day');
};

export const getDayFromDate = date => {
    return dayjs(date).day();
};
export const getConvertDate = date => {
    return dayjs(date).toDate();
};
