import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react';

import '../../assets/css/index.css';
import { ReactComponent as DownArrow } from '../../assets/icons/arrow-down.svg';
import user from '../../assets/image/user.webp';
import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import PageTitle from '../../common/PageTitle';
import Download from '../../common/dropDown/Download';
import { LEAVE_DETAILS_BREADCRUMB } from '../../constants/breadcrumbData';
import { buildLeavePath } from '../../constants/pathNameConstants';
import { applyDate, formatDateByZone } from '../../helper/dateHelper/dateUtils';
import LeaveManager from '../../service/leave-manager';
import YearPicker from '../components/YearPicker';
import '../components/styles.css';

const PAGE_TITLE = 'Employee Leave Details';
const columns = [
    {
        id: 1,
        name: 'Application date',
        selector: row => {
            const dateFormatted = applyDate(row.lastUpdatedOn);
            const unplannedText =
                row.unplanned === true ? (
                    <span className="unplannedLeave">(unplanned leaves)</span>
                ) : (
                    ''
                );

            return (
                <div>
                    {dateFormatted} {unplannedText}
                </div>
            );
        },
        sortable: true,
        reorder: true,
    },
    {
        id: 2,
        name: 'Leave Date (Start - End)',
        selector: row => `${formatDateByZone(row.startFrom, row.endAt)}`,
        sortable: true,
        reorder: true,
    },
    {
        id: 3,
        name: 'No of days',
        selector: row => row.durationInDays,
        sortable: true,
        reorder: true,
    },
    {
        id: 4,
        name: 'Leave Reason',
        selector: row => row.reason,
        sortable: true,
        reorder: true,
    },
];

const EmployeeLeaveDetails = () => {
    const size = 10;
    const { id, year } = useParams();

    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(year);
    const [details, setDetails] = useState();
    const [data, setData] = useState();
    const [selectedDownloadType, setSelectedDownloadType] = useState(null);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const toast = useToast();
    console.log(year);

    useEffect(() => {
        if (!selectedYear) {
            setSelectedYear(currentYear);
        } else {
            fetchEmployeeLeaveDetailsData(selectedYear);
        }

        if (selectedDownloadType) {
            fetchSingleDataDownload();
        }
    }, [selectedYear, selectedDownloadType, page, size]);

    const pickedYear = year => {
        setSelectedYear(year);
    };

    async function fetchEmployeeLeaveDetailsData(selectedYear) {
        try {
            const response = await LeaveManager.getEmployeeLeaveDetails(
                id,
                selectedYear,
                page,
                size
            );
            setDetails(response);
            setData(response?.data?.leaveInfo);
        } catch (error) {
            console.log('object');
        }
    }
    function setPageNumber(pageNumber) {
        setPage(pageNumber);
        navigate(buildLeavePath(id));
    }
    const handleDownloadSelect = type => {
        setSelectedDownloadType(type);
        setTimeout(() => {
            setSelectedDownloadType(null);
        }, 2000);
    };

    async function fetchSingleDataDownload() {
        setDataLoaderOpen(true);
        try {
            const employeeId = id;
            const year = selectedYear || currentYear;
            const format = selectedDownloadType;

            const response = await LeaveManager.getSingleDataDownload(
                year,
                format,
                employeeId
            );

            const blob = new Blob([response], {
                type: format === 'pdf' ? 'application/pdf' : 'text/csv',
            });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `leave-report-${year}.${format}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);

            toast({
                title: 'Failed to fetch Single employee leave',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }
    return (
        <Box>
            <CustomBreadcrumb allBreadcrumbData={LEAVE_DETAILS_BREADCRUMB} />
            <Flex my="16px" justifyContent="space-between">
                <PageTitle title={PAGE_TITLE} />
                <Flex gap="8px">
                    <YearPicker
                        onChange={pickedYear}
                        selectedYear={selectedYear}
                    />
                    <Box alignSelf="center">
                        <Download selectDownload={handleDownloadSelect} />
                    </Box>
                </Flex>
            </Flex>
            <Flex gap="12px">
                <Image
                    className="leaveEmployeeImg"
                    src={details?.data?.employee?.image || user}
                    alt={details?.data?.employee?.name || 'Employee Image'}
                />
                <Box>
                    <Text className="leaveEmployeeName">
                        {details?.data?.employee?.name}
                    </Text>
                    <Text className="leaveEmployeeDesignation">
                        {details?.data?.employee?.designation}
                    </Text>
                    <Text>
                        <Text as="span" className="leaveSupervisor">
                            Supervisor:
                        </Text>{' '}
                        {details?.data?.employee?.supervisor}{' '}
                    </Text>
                </Box>
            </Flex>
            <Box
                className={`employeeTotalLeave ${
                    details?.data?.totalLeaveInDays <= 20
                        ? 'employeeTotalLeaveGreen'
                        : details?.data?.totalLeaveInDays >= 21 &&
                          details?.data?.totalLeaveInDays <= 25
                        ? 'employeeTotalLeaveYellow'
                        : 'employeeTotalLeaveRed'
                }`}
                my="24px"
            >
                <Text>
                    Total Approved Leaves{' '}
                    <Text as="span" className="employeeTotalApprovedLeaveDay">
                        {details?.data?.totalLeaveInDays || 0}
                    </Text>{' '}
                    Days including{' '}
                    <Text as="span" className="employeeTotalApprovedLeaveDay">
                        {details?.data?.unplannedLeaveCount || 0}
                    </Text>{' '}
                    unplanned leaves
                </Text>
            </Box>
            <Box>
                {isDataLoaderOpen ? (
                    <Box className="loader" />
                ) : (
                    <DataTable
                        columns={columns}
                        data={data}
                        sortIcon={<DownArrow />}
                    />
                )}

                <CommonPagination
                    page={page}
                    size={size}
                    totalPages={details?.meta?.totalPages}
                    setPageNo={setPageNumber}
                />
            </Box>
        </Box>
    );
};

export default EmployeeLeaveDetails;
